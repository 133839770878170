<!--
 * @Author: 梁平贤
 * @Date: 2020-07-04 16:55:01
 * @LastEditors: linshanfeng
 * @LastEditTime: 2023-12-26 10:44:04
 * @Description:自由流
 * @FilePath: \user\src\components\businessDetail\components\columnRendering\flow\index.vue
-->
<template>
  <div class="notifier-box">
    <div class="notifier-line-box">
      <div class="iconfont notifier-icon">&#xe859;</div>
      <div class="line-title">审批人</div>
    </div>
    <div class="tip">审批时按照从上往下的顺序流转，可拖动排序</div>
    <div class="notifierColumn">
      <div class="list">
          <div class="person-cent">
            <div class="person-item-add-box" @click.stop="clickSelectAlert">
              <div class="person-item-add">
                <div class="iconfont add-icon">&#xec22;</div>
              </div>
              <div class="name">添加</div>
            </div>
            <draggable v-model="customList" class="draggable" @end="end">
              <div class="person-item" v-for="(p, pindex) in customList" :key="pindex">
                <div class="logo">
                  <en-user-logo :user-name="p.userName" :image-url="p.userLogo || p.img" size="34"></en-user-logo>
                  <span class="name">{{ p.userName }}</span>
                  <en-icon size="14" v-if="p.isApproval !== '001' && p.notAllowDelete !== '000'" name="iconshanchu-liebiao" class="del-item" color="#f76b6b"
                        @click.native="delPerson(pindex)"></en-icon>
                </div>
                <div v-if="customList.length - 1 !== pindex"  class="iconfont jiantou">&#xe81b;</div>
              </div>
            </draggable>
          </div>
          <!-- <en-result icon-size="small" type="NoData" v-else></en-result> -->
        </div>
    </div>
    <select-person v-if="selectPersonDialogVisible"
                   :dialogVisible.sync="selectPersonDialogVisible"
                   name="选择审批人"
                   :multi="false"
                   @refreshList="getPersonList"></select-person>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import selectPerson from "./components/selectPerson";

export default {
  name: "BMFlowColumn",
  components: { selectPerson, draggable },
  props: {
    column: {
      type: Object,
      require: true,
      default() {
        return {};
      }
    },
    // 可能需要的整体数据
    businessData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      showAlert: false,
      attention: [{ label: "审批时按照从上往下的顺序流转", showDot: false }],
      flowData: [],
      showList: false,
      mdInfo: {},
      // 审批人数据
      customList: [],
      showContent: [],
      value: [],
      delList: [],
      selectPersonDialogVisible: false// 选择人员数据弹框
    };
  },
  mounted() {
    this.customList = JSON.parse(JSON.stringify(this.businessData.mdInfo.customList)) || [];
  },
  methods: {
    end() {
      console.log(this.customList);
    },
    /**
     * @description: 获取子组件的数据
     */
    getPersonList(list) {
      const mod = list[0];
      const addMod = {
        nodeId: "",
        userId: mod.id,
        userName: mod.name || mod.text,
        userLogo: mod.userLogo || mod.icon || "",
        isApproval: "000"
      };

      this.customList.push(addMod);
      this.setCustomList(addMod);
    },
    /**
     * @description: 整理提交数据
     */
    setCustomList() {
      const newArr = [];
      this.customList.map((item) => {
        let newitem = {};
        // 新增的和未改变的数据
        newitem = {
          nodeId: item.nodeId || "",
          opFlag: item.nodeId ? "" : "000",
          userId: item.userId,
          userName: item.userName,
          executor: item.userId || "",
          nodeName: item.userName || "",
          notAllowDelete: item.notAllowDelete || "",
          isApproval: item.isApproval || ""
        };
        newArr.push(newitem);
        return newArr;
      });
      this.delList.map((item) => {
        // 删除的数据
        let delitem = {};
        delitem = {
          nodeId: item.nodeId || "",
          userId: item.userId || "",
          userName: item.userName || "",
          opFlag: "001",
          executor: item.userId || "",
          nodeName: item.userName || "",
          notAllowDelete: item.notAllowDelete || "",
          isApproval: item.isApproval || ""
        };
        newArr.push(delitem);
        return newArr;
      });
      this.businessData.mdInfo.customList = newArr;
    },
    /**
     * @description: 弹出选择人员弹窗
     */
    clickSelectAlert() {
      this.selectPersonDialogVisible = true;
      this.showList = true;
      this.showContent.push("1");
    },
    /**
     * @description: 删除人员人员弹窗
     */
    delPerson(index) {
      const delmod = this.customList[index];
      this.customList.splice(index, 1);
      if (delmod.nodeId) {
        this.delList.push(delmod);
      }
      this.setCustomList();
    },
    /**
     * @description: 点击展开流程列表
     */
    changeShowList(val) {
      this.showList = !!val.length;
    }
  }
};
</script>

<style lang="scss" scoped>
.notifier-box {
  .notifier-line-box{
      display: flex;
      align-items: center;
      .notifier-icon{
        width: 16px;
        height: 16px;
        color: #FFAD2C
      }
      .line-title{
        width: 43px;
        height: 14px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 600;
        color: #1A1C1E;
        margin-left: 8px;
      }
    }
  .tip{
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #636C78;

  }
    .notifierColumn{
      margin-top: 10px;
      background: #fff;
      border: 1px solid #e8ecf2;
      border-radius: 4px;
      padding: 21px;
      .list {
      // border-top: 1px solid #e8ecf2;
      padding-right: 20px;
      position: relative;
      overflow: hidden;
      text-align: left;
    }
    .person-cent {
      display: flex;
      padding: 10px 0;
      .person-item-add-box{
        display: flex;
        align-items: center;
        flex-direction:column;
        justify-content: center;
        .person-item-add{
            width: 34px;
            height: 34px;
            background: #F5F8FC;
            border: 1px solid #9EC7FE;
            box-shadow: 0px 3px 3px 0px rgba(62,144,254,0.15);
            border-radius: 50%;
            display: flex;
            cursor: pointer;
            justify-content: center;
            .add-icon{
              width: 16px;
              height: 16px;
              color: #3E90FE;
            }
        }
        .name{
          text-align: center;
        }
      }
      .draggable{
        display: flex;
      }
      .person-item {
        cursor: pointer;
        display: flex;
        .logo{
          position: relative;
          .en-user-logo {
            margin: 0 auto;
            display: block;
          }
          .name {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 50px;
            text-align: center;
            margin: auto;
          }
          .del-item {
            position: absolute;
            top: -3px;
            right: 0;
            display: none;
          }
          &:hover {
            .del-item {
              display: block;
            }
          }
          span {
            display: block;
            text-align: center;
            color: #636c78;
          }
        }
        .jiantou{
            margin: 0 20px;
            font-size: 10px;
            color:#BAC4D1;
          }
      }
      .person-item:first-of-type{
        margin-left: 30px;
      }
    }
    .el-collapse {
      border: 0;
    }
    /deep/ .el-collapse-item__header {
      background-color: #f5f7fa;
      border: 0;
    }
    .el-collapse-item:last-child {
      margin-bottom: 0;
    }
    .el-collapse {
      border: 0;
    }
    /deep/ .el-collapse-item__header {
      background-color: #f5f7fa;
      border: 0;
    }
    .el-collapse-item:last-child {
      margin-bottom: 0;
    }
    /deep/ .el-collapse-item__content {
      border-bottom: 0;
      padding-bottom: 0;
      font-size: 12px;
    }
    /deep/ .el-collapse-item__wrap {
      border-bottom: 0;
    }
    }
  .top-head {
    background-color: #f5f7fa;
    height: 48px;
    padding-left: 21px;
    text-align: left;
    zoom: 1;
    font-size: 12px;
    &::after {
      content: "";
      display: block;
      clear: both;
    }
    .txts {
      font-weight: bold;
      float: left;
      line-height: 48px;
    }
    .notices-txt {
      float: left;
      background: #f7f0eb;
      color: #fd9a4a;
      padding: 0 10px;
      border-radius: 30px;
      height: 22px;
      line-height: 22px;
      margin: 13px 0 0 10px;
    }
    .head-notice {
      float: left;
      margin: 2px 0 0 8px;
    }
    .head-add {
      float: left;
      margin-left: 8px;
      cursor: pointer;
      .en-icon {
        float: left;
        margin-top: 15px;
      }
      .txts {
        margin-left: 3px;
        color: #636c78;
        font-weight: normal;
      }
    }
    .show-btn {
      float: right;
      color: #66a7e5;
      margin: 16px 18px 0 0;
      cursor: pointer;
    }
  }
  .el-collapse {
    border: 0;
  }
  /deep/ .el-collapse-item__header {
    background-color: #f5f7fa;
    border: 0;
  }
  .el-collapse-item:last-child {
    margin-bottom: 0;
  }
  /deep/ .el-collapse-item__content {
    border-bottom: 0;
    padding-bottom: 0;
    font-size: 12px;
  }
  /deep/ .el-collapse-item__wrap {
    border-bottom: 0;
  }
}
</style>
