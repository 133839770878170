var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "notifier-box" },
    [
      _vm._m(0),
      _c("div", { staticClass: "tip" }, [
        _vm._v("审批时按照从上往下的顺序流转，可拖动排序"),
      ]),
      _c("div", { staticClass: "notifierColumn" }, [
        _c("div", { staticClass: "list" }, [
          _c(
            "div",
            { staticClass: "person-cent" },
            [
              _c(
                "div",
                {
                  staticClass: "person-item-add-box",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.clickSelectAlert.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm._m(1),
                  _c("div", { staticClass: "name" }, [_vm._v("添加")]),
                ]
              ),
              _c(
                "draggable",
                {
                  staticClass: "draggable",
                  on: { end: _vm.end },
                  model: {
                    value: _vm.customList,
                    callback: function ($$v) {
                      _vm.customList = $$v
                    },
                    expression: "customList",
                  },
                },
                _vm._l(_vm.customList, function (p, pindex) {
                  return _c(
                    "div",
                    { key: pindex, staticClass: "person-item" },
                    [
                      _c(
                        "div",
                        { staticClass: "logo" },
                        [
                          _c("en-user-logo", {
                            attrs: {
                              "user-name": p.userName,
                              "image-url": p.userLogo || p.img,
                              size: "34",
                            },
                          }),
                          _c("span", { staticClass: "name" }, [
                            _vm._v(_vm._s(p.userName)),
                          ]),
                          p.isApproval !== "001" && p.notAllowDelete !== "000"
                            ? _c("en-icon", {
                                staticClass: "del-item",
                                attrs: {
                                  size: "14",
                                  name: "iconshanchu-liebiao",
                                  color: "#f76b6b",
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.delPerson(pindex)
                                  },
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm.customList.length - 1 !== pindex
                        ? _c("div", { staticClass: "iconfont jiantou" }, [
                            _vm._v(""),
                          ])
                        : _vm._e(),
                    ]
                  )
                }),
                0
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm.selectPersonDialogVisible
        ? _c("select-person", {
            attrs: {
              dialogVisible: _vm.selectPersonDialogVisible,
              name: "选择审批人",
              multi: false,
            },
            on: {
              "update:dialogVisible": function ($event) {
                _vm.selectPersonDialogVisible = $event
              },
              "update:dialog-visible": function ($event) {
                _vm.selectPersonDialogVisible = $event
              },
              refreshList: _vm.getPersonList,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "notifier-line-box" }, [
      _c("div", { staticClass: "iconfont notifier-icon" }, [_vm._v("")]),
      _c("div", { staticClass: "line-title" }, [_vm._v("审批人")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "person-item-add" }, [
      _c("div", { staticClass: "iconfont add-icon" }, [_vm._v("")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }